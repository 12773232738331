import React, { useEffect, useState } from 'react'
import Slider from "react-slick"; 
import {getEvents} from'../Service/Api'
const Events = () => {
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
     useEffect(() => {
         const fetchData = async () =>{
                try{
                   const eventData = await getEvents();
                   setData(eventData) ;  
                }catch(error){
                 setError(error);
                }finally{
                 setLoading(false)
                }
         }
         fetchData();
     },[]);
 
     const emptyArray = [
        { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM",time:"00:00",venue:"School Campus"},
        { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM",time:"00:00",venue:"School Campus"},
        { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM",time:"00:00",venue:"School Campus"}
 
     ]
    const settings = {
        autoplay: true,
        infinite: true, 
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow:4,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false, 
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3, 
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2, 
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1, 
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1, 
            }
          }
        ]
      };
    
  return (
     <> 
     <div className="row">
     <Slider {...settings}> 
        {data && data.length > 0 ? (data.map((item,index) => {  
                 const date = new Date(item.date);
                 const mon = monthNames[date.getMonth()];
                 const eventdate = date.getDate();
                      console.log(mon);

                  return (
                    <div className="item" key={index}>
                    <div className="homeeventbox">
                        <div className="homeeventboxdate">{eventdate} {mon}</div>
                         <div className="homeeventboximg">
                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.images}`} className="img-fluid" alt="St. Basil School, Basti"/>
                             <div className="homeeventboxtitle"><p> {item.title} </p></div>
                         </div>
                         <div className="homeeventboxdesc">
                                 <p>{item.description}</p>
                            
                            <div className="homeeventboxbottom">
                                <span><i className="bi bi-geo-alt-fill"></i>{item.venue}</span>
                                <span><i className="bi bi-clock-fill"></i>{item.time} </span>
                            </div>
                         </div>
                     </div>
               </div> 


                  )
               
         })) : (emptyArray.map((item,index) => (
                <div className="item" key={index}>
                <div className="homeeventbox">
                    <div className="homeeventboxdate">{item.date}</div>
                     <div className="homeeventboximg">
                        <img src="/Images/events.jpg" className="img-fluid" alt="St. Basil School, Basti"/>
                         <div className="homeeventboxtitle"><p> {item.title} </p></div>
                     </div>
                     <div className="homeeventboxdesc">
                             <p>{item.description}</p>
                        
                        <div className="homeeventboxbottom">
                            <span><i className="bi bi-geo-alt-fill"></i>{item.venue}</span>
                            <span><i className="bi bi-clock-fill"></i>{item.time} </span>
                        </div>
                     </div>
                 </div>
           </div> 
           )))}     
          </Slider> 
     </div>
     </>
  )
}

export default Events