import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {getNews} from'../Service/Api'
import Slider from "react-slick"; 
const News = () => {
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null);


     useEffect(() =>{
           const fetcData = async () =>{
                   try{
                        const newsData = await getNews();
                        setData(newsData);
                        console.log(newsData);
                   }catch(error){
                       setError(error);
                   }finally{
                       setLoading(false);
                   }
           }
           fetcData();
     },[])
     const emptyData = [
       {index:"01",title:"Stay Tunned For More Updates",description:"Stay Tunned For More Updates" },
       {index:"02",title:"Stay Tunned For More Updates",description:"Stay Tunned For More Updates" },
       {index:"03",title:"Stay Tunned For More Updates",description:"Stay Tunned For More Updates" },
       {index:"04",title:"Stay Tunned For More Updates",description:"Stay Tunned For More Updates" }

   ]
    const settings = {
        autoplay: true,
        infinite: true, 
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow:4,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false, 
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3, 
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2, 
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1, 
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1, 
            }
          }
        ]
      };
    
  return (
     <>
     <Slider {...settings}> 
        { data.length > 0 ? (data.map((item,index) =>( 
          <div className="item" key={index}>
            <div className="homenewsbox">
                <div className="homenewsboxtitle">
                    <h5>{item.title}</h5>
                </div>
                <div className="homenewsboxdesce">
                    <p>{item.description}</p>
                </div>
                <div className="homenewsboxdate">
                <span>{item.index}</span>
                <div className="attch">
                    <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank"><i className="bi bi-paperclip"></i></Link>
                </div>
            </div>
        </div>
     </div>
     
       ))): (emptyData.map((item,index) => ( 
        <div className="item" key={index}>
            <div className="homenewsbox">
                <div className="homenewsboxtitle">
                    <h5>{item.title}</h5>
                </div>
                <div className="homenewsboxdesce">
                    <p>{item.description}</p>
                </div>
                <div className="homenewsboxdate">
                <span>{item.index}</span>
                <div className="attch">
                    <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                </div>
            </div>
        </div>
    </div>
        
       ))) }
     </Slider> 
     </>
  )
}

export default News
