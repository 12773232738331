import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getHighlight } from '../Service/Api';

const Highlights = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getHighlight();
        setData(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const emptyData = [
    { id: 1, title: "Stay Tuned For More Updates" },
  ];

  return (
    <>
      <div className="highlights">
        <marquee
          onMouseOver={(event) => event.currentTarget.stop()}
          onMouseOut={(event) => event.currentTarget.start()}
        >
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <p key={index}>
                {item.attachments && item.attachments.length > 0 ? (
                  <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank">
                    {item.title} <i className="bi bi-paperclip"></i>
                  </Link>
                ) : (
                  <span>{item.title}</span>
                )}
                {' | '}
              </p>
            ))
          ) : (
            emptyData.map((item) => (
              <p key={item.id}>
                <span>{item.title}</span> {' | '}
              </p>
            ))
          )}
        </marquee>
      </div>
    </>
  );
};

export default Highlights;
